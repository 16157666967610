import React from 'react';
import '../styles/flashcardButton.css';

interface IconButtonProps {
  onClick: () => void;
  imgSrc: string;
}

const IconButton: React.FC<IconButtonProps> = ({ onClick, imgSrc }) => {
  return (
    <div className="flashcardContainer" onClick={onClick}>
      <img src={imgSrc} alt="Icon button" />
    </div>
  );
};

export default IconButton;