import { AFrame } from 'aframe';

interface IFaceCameraAframe {
    el: AFrame['AEntity'];
    camera: any;
  }

  const FaceCamera = {
    name: 'face-camera',
    val: {
        init(this : IFaceCameraAframe) {
            this.camera = this.el.sceneEl?.camera
        },
        tick(this : IFaceCameraAframe) {
            this.el.object3D.lookAt(this.camera.el.object3D.position)
        }, 
    },
};
export { FaceCamera as FaceCameraComponent }